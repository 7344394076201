<template>
  <module-container
    route-name="reportjob"
    show-toolbar
    :title="$t('reportjob')"
    hide-new-button
  >
    <template #route>
      <router-view />
    </template>

    <template #filters>
      <report-job-filters />
    </template>
  </module-container>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";

import PaginateProviderMixin from "@/mixins/PaginateProviderMixin";
import ReportJobFilters from "../components/ReportJobFilters.vue";

@Component({
  components: {
    ReportJobFilters,
  },
})
export default class ReportJobIndex extends Mixins(PaginateProviderMixin) {}
</script>
